<template>
  <div class="default-tabela usuario-tabela">
    <div class="tabela-titulo">
      <h1>{{ camposInput.titulo }}</h1>
      <div style="position: relative;">
        <DownloadButton area="relatorio_de_participacoes" :getData="downloadExcelAll" :title="'Relatório de Usuários'" />
      </div>
    </div>

    <div class="tabela-subtitulo default-formulario">
      <div class="formulario-item">
        <label class="formulario-item-label">Nome:</label>
        <input type="text" v-model="form.nome" placeholder="Nome" />
      </div>

      <div class="formulario-item">
        <label class="formulario-item-label">CPF:</label>
        <input type="text" v-model="form.cpf" v-mask="'999.999.999-99'" placeholder="000.000.000-00" />
      </div>
      <div class="formulario-item">
        <label class="formulario-item-label">E-mail:</label>
        <input
          type="text"
          v-model="form.email"
          placeholder="email@exemplo.com"
        />
      </div>

      <div class="formulario-item" style="width: fit-content;flex-direction: row;gap: 20px;">
        <div class="container-input container-datepicker">
          <label class="formulario-item-label">Data início:</label>
          <datepicker v-model="form.dataInicial" :language="ptBR"></datepicker>
        </div>
        <div class="container-input container-datepicker">
          <label class="formulario-item-label">Data final:</label>
          <datepicker v-model="form.dataFinal" :language="ptBR"></datepicker>
        </div>
      </div>
    </div>

    <!-- .tabela-filtro -->
    <div class="tabela-subtitulo default-formulario">

      <div class="btns-formulario" style="margin-left: auto;">
        <button class="btn btn-enviar" @click="filtrar">Filtrar</button>
        <button class="btn" @click="limparFiltros">Limpar</button>
      </div>

      <!-- .tabela-filtro -->
    </div>

    <transition name="fade-left" mode="out-in">
      <div v-if="semDados" class="tabela-sem-dados" key="Vazio">
        <p>Sem dados no momento</p>
      </div>
      <div v-else class="tabela-informacoes" key="Lista">
        <div class="tabela-linha tabela-linha-titulo">
          <div class="tabela-linha-nome">
            <p>Nome</p>
          </div>
          <div class="tabela-linha-cpf">
            <p>CPF</p>
          </div>
          <div class="tabela-linha-email">
            <p>E-mail</p>
          </div>
          <div class="tabela-linha-cadastro">
            <p>Data de cadastro</p>
          </div>
          <!-- <div class="tabela-linha-status">
            <p>Usuário<br />ativo</p>
          </div>
          <div class="tabela-linha-status"><p>Usuário bloqueado</p></div>
          <div class="tabela-linha-status"><p>Email confirmado</p></div>
          <div class="tabela-linha-meio"><p>Meio de cadastro</p></div> -->

          <div class="tabela-linha-btns"></div>
        </div>
        <div class="tabela-linha tabela-linha-informacoes" v-for="(itemTabela, index) in dadosTabela" :key="index">
          <div class="tabela-linha-nome">
            <p>{{ itemTabela.nome }}</p>
          </div>
          <div class="tabela-linha-cpf">
            <p>{{ itemTabela.cpf | formataCPF }}</p>
          </div>
          <div class="tabela-linha-email">
            <p>{{ itemTabela.email }}</p>
          </div>
          <div class="tabela-linha-cadastro">
            <p>{{ itemTabela.dataCadastro | filterData }}</p>
          </div>
          <!-- <div class="tabela-linha-status">
            <p v-if="itemTabela.usuario.ativo">Sim</p>
            <p v-else>Não</p>
          </div>
          <div class="tabela-linha-status">
            <p v-if="itemTabela.usuario.bloqueado">Sim</p>
            <p v-else>Não</p>
          </div>
          <div class="tabela-linha-status">
            <p v-if="itemTabela.usuario.emailConfirmado">Sim</p>
            <p v-else>Não</p>
          </div> -->
          <!-- <div class="tabela-linha-meio">
            <p>{{ itemTabela.meioDeCadastro }}</p>
          </div> -->

          <div class="tabela-linha-btns">
            <div class="tabela-linha-btns-editar" @click="verItem(itemTabela.usuarioId)">
              <img src="@/assets/images/icones/see.svg" alt="Detalhes" title="Detalhes" />
            </div>
          </div>
        </div>
      </div>
    </transition>
    <div class="tabela-paginacao paginacao" v-if="!semDados">
      <div>
        <p>Total</p>
      </div>
      <div>
        {{ paginacao.totalPaginas }}
      </div>
      <div class="paginacao-btn" v-if="paginacao.numeroPagina >= 2" @click="nudaPagina(1)">
        <p>1</p>
      </div>
      <div>
        <div class="button" v-if="pageNumber > 1" @click="prevPage()">
          <img src="~@/assets/images/icones/next.svg" alt="<" title="Anterior" style=" transform: rotate(180deg);" />
        </div>
      </div>
      <div v-if="paginacao.numeroPagina > 1" @click="nudaPagina(paginacao.numeroPagina - 1)">
        <p>{{ paginacao.numeroPagina - 1 }}</p>
      </div>
      <div class="pagina-atual" v-if="paginacao.numeroPagina >= 1">
        {{ paginacao.numeroPagina }}
      </div>
      <div v-if="paginacao.totalPaginas >= 3 &&
        paginacao.numeroPagina < paginacao.totalPaginas
        " @click="nudaPagina(paginacao.numeroPagina + 1)">
        <p>{{ paginacao.numeroPagina + 1 }}</p>
      </div>
      <div>
        <div class="button" v-if="dadosTabela.length === 10" @click="nextPage()">
          <img src="~@/assets/images/icones/next.svg" alt=">" title="Próximo" />
        </div>
      </div>
      <div class="paginacao-btn" v-if="paginacao.numeroPagina < paginacao.totalPaginas"
        @click="nudaPagina(paginacao.totalPaginas)">
        <p>{{ paginacao.totalPaginas }}</p>
      </div>
    </div>
    <!-- <Paginacao :paginacao="paginacao" pagina="usuario"  v-if="!semDados"/> -->
    <!-- .tabela-paginacao -->
  </div>
</template>

<script>
import AwesomeMask from "awesome-mask";
import { usuarioListarTodos, gerarRelatorio, GetExcelAdminById } from "@/api/usuario";
import { _imagemMobile, _imagemDesktop } from "@/api/_caminhosApi";
import { numberToReal } from "@/utils/money";
import { filterData, stringToDatetimeZone } from "@/utils/date";
import { formataCPF } from "@/utils/masks";
import DownloadButton from "@/components/download-button/DownloadButton.vue";
import Datepicker from "vuejs-datepicker";
import { ptBR } from "vuejs-datepicker/dist/locale";
// import moment from "moment";
// import Paginacao from "@/components/paginacao/Paginacao.vue";

export default {
  directives: {
    mask: AwesomeMask
  },
  data() {
    return {
      ptBR: ptBR,
      planilhaParaDownload: [],
      tituloDownload: "",
      pageNumber: 1,
      filtroBotao: false,
      semDados: true,
      form: {
        nome: null,
        cpf: null,
        email: null,
        dataInicial: null,
        dataFinal: null
      },
      filtros: {},
      calendario: {
        inicio: "",
        final: ""
      },
      calendarioTexto: {
        placeholder: "Selecione o período",
        fromText: "De",
        toText: "a",
        resetText: "Limpar",
        confirmText: "Confirmar",
        weekList: ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sab"],
        monthList: [
          "Janeiro",
          "Fevereiro",
          "Março",
          "Abril",
          "Maio",
          "Junho",
          "Julho",
          "Agosto",
          "Setembro",
          "Outubro",
          "Novembro",
          "Dezembro"
        ]
      }
    };
  },
  components: {
    DownloadButton,
    Datepicker
    // Paginacao
  },
  computed: {
    camposInput() {
      return this.$store.state.usuario.componenteAtivado;
    },
    dadosTabela() {
      return this.$store.state.usuario.tabela;
    },
    paginacao() {
      return this.$store.state.usuario.paginacao;
    }
  },
  methods: {
    _imagemMobile,
    _imagemDesktop,
    numberToReal,
    gerarRelatorio,
    montarDownload(data) {
      this.tituloDownload = ["Relatório de Usuários"];
      if (data && Object.keys(data).length) {
        return (this.planilhaParaDownload = data.map((v) => ({
          Nome: v.nome,
          "E-mail": v.email,
          CPF: this.formataCPF(v.cpf),
          "Data de Nascimeto": v.dataNascimento,
          "Hora de cadastro": v.dataCadastro.slice(11, -3),
          "Data de cadastro": v.dataCadastro.slice(0, -9),
          "Profissional da construção": v.profissionalDaConstrucao,
          DD: this.getDD(v.celular),
          Celular: this.removeDD(v.celular),
          Telefone: this.removeDD(v.telefone),
          CEP: v.cep,
          Endereco: v.endereco,
          "Número do Endereço": v.numero,
          Complemento: v.complemento ? v.complemento : "Não informado",
          Bairro: v.bairro,
          Cidade: v.cidade,
          UF: v.uf,
          "Cupons Cadastrados": v.quantidadeCuponsCadastrados,
          "Valor Cupons Cadastrados": v.valorCuponsCadastrados,
          "Valor Cupons Aprovados": v.valorCuponsAprovados,
          "Valor Cupons Reprovados": v.valorCuponsReprovados,
          "Saldo Atual": v.saldoAtual,
          "Meio de Cadastro": v.meioDeCadastro,
          "Aceite de Comunicação": v.aceiteEmail,
        })));
      }
    },
    getDD(number) {
      return number.substring(0, 2);
    },
    removeDD(number) {
      if (number) return (number = number.replace(/^.{2}/g, ""));
    },
    prevPage() {
      this.pageNumber = this.pageNumber - 1;
    },
    nextPage() {
      this.pageNumber = this.pageNumber + 1;
    },
    nudaPagina(id) {
      this.pageNumber = id;
    },
    novoEnvio() {
      this.$store.commit("usuario/SET_TIPO_FORMULARIO", "Novo");
      this.$store.commit("usuario/SET_USUARIO_TELA", "Formulario");
    },
    verificaComponente() {
      return usuarioListarTodos(this.pageNumber, 10, this.filtros, this.filtroBotao);
    },
    formataCPF(cpf) {
      cpf = cpf.replace(/[^\d]/g, "");
      return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
    },
    editarItem(id) {
      this.$store.commit("usuario/SET_TIPO_FORMULARIO", "Editar");
      this.$store.commit("usuario/SET_USUARIO_TELA", "Formulario");
      this.$store.commit("usuario/SET_ID_FORMULARIO_EDITAR", id);
    },
    verItem(id) {
      this.$store.commit("usuario/SET_TIPO_FORMULARIO", "Detalhes");
      this.$store.commit("usuario/SET_USUARIO_TELA", "Detalhes");
      this.$store.commit("usuario/SET_ID_FORMULARIO_EDITAR", id);
    },
    fecharSelect(item) {
      if (!this.form[item] || this.form[item].length < 1) {
        this.form[item] = null;
      }
      this.pageNumber = 1;
      this.verificaComponente();
    },
    filtrar() {
      this.filtros = this.form;
      this.filtroBotao = true
      this.verificaComponente();
      this.filtroBotao = false
    },
    limparFiltros() {
      this.form = {
        nome: null,
        cpf: null,
        email: null,
        dataCadastroInicial: null,
        dataCadastroFinal: null
      };
      this.filtros = {};
      this.filtroBotao = false
      this.resetCalendario();
      this.verificaComponente();
    },
    atualizaCalendario(data) {
      this.form.dataCadastroInicial = stringToDatetimeZone(data.start);
      this.form.dataCadastroFinal = stringToDatetimeZone(data.end);
    },
    resetCalendario() {
      this.form.dataCadastroInicial = "";
      this.form.dataCadastroFinal = "";
    },
    getData() {
      return this.gerarRelatorio().then(resp => {
        return this.montarDownload(resp.data);
      });
    },
    downloadExcelAll() {
      GetExcelAdminById().then(
        (res) => {
          const fileURL = window.URL.createObjectURL(new Blob([res.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          }));
          const fileLink = document.createElement("a");
          fileLink.href = fileURL;
          fileLink.setAttribute("download", "RelatorioUsuarios.xlsx");
          document.body.appendChild(fileLink);
          fileLink.click();
          this.$store.commit("interacoes/SET_LOADING", false);
        },
        (error) => {
          this.$store.commit("SET_ALERTA", {
            show: true,
            tipo: "erro",
            mensagem: error.response.data,
          });
        }
      );
    },
  },
  filters: {
    filterData,
    formataCPF
  },
  mounted() {
    this.pageNumber = 1;
    this.verificaComponente();
    this.limparFiltros();
    this.montarDownload();
  },
  watch: {
    camposInput() {
      this.verificaComponente();
    },
    dadosTabela() {
      this.semDados = this.dadosTabela.length <= 0;
    },
    pageNumber() {
      this.verificaComponente();
    }
  }
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
